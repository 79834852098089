//style presets
:root {
    --msv-text-block-font-color: var(--msv-font-primary-color);
    --msv-text-block-font-size-s: var(--msv-body-font-size-s);
    --msv-text-block-font-size-m: var(--msv-body-font-size-m);
    --msv-text-block-font-size-l: var(--msv-body-font-size-l);
    --msv-text-block-font-size-xl: var(--msv-body-font-size-xl);
}

.lobas-roof-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse !important;
    width: 100%;
  }

.lobas-roof-table table {
    border-collapse: collapse !important;
}

.lobas-roof-table td, thead {
    border: 1px solid #ddd;
    padding: 8px;
  }

.lobas-roof-table a{
    text-decoration: underline;
    text-decoration-color: blue;
    color: blue;

}

.lobas-roof-table tr:nth-child(even){background-color: #f2f2f2;}

.lobas-roof-table tr:hover {background-color: #ddd;}

.lobas-roof-table th {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding:5px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #044caa;
    color: white !important;
  }